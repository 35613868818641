.reservas-container {
    position: fixed;
    width: 100%;
    height: 80px;
    background-color: var(--gris-nav);
    padding: 14px 0;
    box-sizing: border-box;
    bottom: 0;
}

.reservasText-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.reservasText-reservas-container {
    text-align: center;
    line-height: 30px;
    width: 120px;
}
.reservasText-reservas-container p, .text-telefono, .text-email {
    margin: 0;
    font-family: 'Archivo', sans-serif;
    color: var(--icon-enabled);
    font-size: 14px;
    font-weight: 700;
}
.barrita {
    height: auto;
    width: 2px;
    background-color: var(--icon-enabled);
}
.icon-telefono, .icon-mail, .text-email, .text-telefono {
    color: var(--icon-enabled);
    display: inline;
}
.text-email, .text-telefono {
    font-size: 12px;
}
.icon-telefono, .icon-mail {
    font-size: 16px;
    margin: 0 3px 0 7px;
}
.reservasText-mail-container, .reservasText-telefono-container {
    display: flex;
    align-items: center;
}


@media screen and (min-width: 600px) {
    .reservas-container {
        padding: 25px 22px 25px 13px;
    }
}

